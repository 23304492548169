import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend
} from 'recharts';
import dynamicClass from 'classnames';
import { useOptimizely } from '~/context/Optimizely';
import { usePublicRuntimeConfig } from '~/hooks/usePublicRuntimeConfig';

const renderCustomLegend = (
  ctaColor?: string,
  ctaText?: string,
  ctaTextColor?: string,
  registrationButton?: boolean,
  ctaDestination?: string,
  webregURL?: string
) => (
  <div className="mt-6 flex items-center justify-start tablet:mt-8">
    <div className="mr-5 flex h-fit items-center rounded-[40px] bg-white px-2 font-medium">
      <div className="mr-1 size-[14px] rounded-full bg-light-mint" />
      <span className="text-sm">Saved</span>
    </div>
    <div className="flex h-fit items-center rounded-[40px] bg-white px-2 font-medium">
      <div
        className="mr-2 size-[14px] rounded-full"
        style={{
          background: 'linear-gradient(81deg, #7497ea 0%, #07c8ab 100%)'
        }}
      />
      <span className="text-sm">Earned</span>
    </div>
    {ctaText && (
      <button
        className={`-mr-4 ml-auto hidden min-w-32 items-center justify-center rounded-lg px-4 py-3 text-center text-base font-medium leading-4 tablet:flex`}
        {...((ctaColor || ctaTextColor) && {
          style: { backgroundColor: ctaColor, color: ctaTextColor }
        })}
      >
        <a type="button" href={registrationButton ? webregURL : ctaDestination}>
          {ctaText}
        </a>
      </button>
    )}
  </div>
);

export interface SavingsCalculatorChartProps {
  chartData: any;
  estimatedSavings: number;
  savingsAgeRange: [number, number];
  chartTitle?: string;
  ctaColor?: string;
  ctaText?: string;
  ctaTextColor?: string;
  registrationButton?: boolean;
  ctaDestination?: string;
}

const renderActiveDot = (props: any, maxAge: number, minAge: number) => {
  const { cx, cy } = props;
  return (
    <>
      <g transform={`translate(${cx}, ${cy})`}>
        <g
          transform={dynamicClass({
            'translate(-72, -95)':
              props.payload.age > minAge && props.payload.age < maxAge,
            'translate(5, -95)': props.payload.age === minAge,
            'translate(-175, 15)': props.payload.age === maxAge
          })}
        >
          <svg width="auto" height="auto" xmlns="http://www.w3.org/2000/svg">
            <foreignObject x="0" y="0" width="100%" height="100%">
              <div className="inline-flex h-[94px] flex-col items-start justify-center gap-3 rounded-lg bg-white p-3 drop-shadow-[0_0_4px_rgba(0,0,0,0.2)]">
                <div className="text-sm font-medium">
                  Savings by age {props.payload.age}
                </div>
                <div className="flex flex-col items-center justify-start gap-2">
                  <div className="inline-flex items-center justify-start gap-0.5 self-stretch rounded-[40px] bg-white pr-2">
                    <div className="flex h-5 w-5 items-center justify-center">
                      <div className="relative h-[14px] w-[14px] rounded-full border border-navy bg-light-mint" />
                    </div>
                    <div className="text-center text-sm leading-[14px]">
                      ${props.payload.saved} Saved
                    </div>
                  </div>
                  <div className="inline-flex items-center justify-start gap-0.5 self-stretch rounded-[40px] bg-white pr-2">
                    <div className="flex h-5 w-5 items-center justify-center">
                      <div
                        className="relative h-[14px] w-[14px] rounded-full border border-navy"
                        style={{
                          background:
                            'linear-gradient(81deg, #7497ea 0%, #07c8ab 100%)'
                        }}
                      />
                    </div>
                    <div className="text-center text-sm leading-[14px]">
                      ${props.payload.earned} Earned
                    </div>
                  </div>
                </div>
              </div>
            </foreignObject>
          </svg>
        </g>
      </g>
    </>
  );
};

export const SavingsChart = ({
  chartData,
  estimatedSavings,
  savingsAgeRange,
  chartTitle,
  ctaColor,
  ctaText,
  ctaTextColor,
  registrationButton,
  ctaDestination
}: SavingsCalculatorChartProps) => {
  const experiments = useOptimizely();

  const { webregUrl: fallBackWebregUrl, onboardingUrl } =
    usePublicRuntimeConfig();

  const webregURL = experiments?.website_onboarding_initialize?.enabled
    ? onboardingUrl
    : fallBackWebregUrl;

  return (
    <div className="w-full rounded-2xl bg-[#F3F6F5] px-4 py-6 shadow-sm tablet:p-10 desktop:w-2/3">
      <div className="mb-6 tablet:mb-8">
        <p className="mb-4 text-center text-base font-medium tablet:text-start">
          {chartTitle} {savingsAgeRange[0]} - {savingsAgeRange[1]}
        </p>
        <h2 className="hidden text-[82px] font-semibold leading-[120%] tablet:block">
          ${estimatedSavings.toFixed(2)}
        </h2>
      </div>
      <ResponsiveContainer
        width="100%"
        height={435}
        className="relative overflow-visible"
      >
        <AreaChart
          data={chartData}
          className="overflow-visible [&>svg]:overflow-visible"
        >
          <defs>
            <radialGradient
              id="colorEarned"
              cx="50%"
              cy="50%"
              r="50%"
              fx="80%"
              fy="80%"
            >
              <stop offset="0%" stopColor="#7497ea" stopOpacity={1} />
              <stop offset="100%" stopColor="#07c8ab" stopOpacity={1} />
            </radialGradient>
          </defs>
          <XAxis
            dataKey="age"
            tick={{ fill: '#000', transform: 'translate(0, 10)' }}
            tickLine={false}
          />
          <YAxis
            domain={[0, estimatedSavings]}
            tickFormatter={(value) =>
              value > 1000
                ? `$${(value / 1000).toFixed(1)}k`
                : `$${Math.ceil(value)}`
            }
            interval="preserveStartEnd"
            ticks={[
              0,
              estimatedSavings * 0.25,
              estimatedSavings * 0.5,
              estimatedSavings * 0.75,
              estimatedSavings
            ]}
            tick={{ fill: '#000', transform: 'translate(-10, 0)' }}
            axisLine={false}
            tickLine={false}
          />
          <Area
            type="monotone"
            dataKey="total"
            stroke="#07c8ab"
            fillOpacity={1}
            fill="url(#colorEarned)"
            activeDot={false}
          />
          <Area
            type="monotone"
            dataKey="saved"
            stroke="#99EDC5"
            fillOpacity={1}
            fill="#99EDC5"
            className="z-0"
            activeDot={false}
          />
          <CartesianGrid stroke="#1A353826" horizontal vertical={false} />
          <Tooltip
            wrapperClassName="hidden"
            cursor={{
              stroke: '#035C67',
              strokeWidth: 1,
              strokeDasharray: '5 5'
            }}
          />
          <Area
            type="monotone"
            dataKey="total"
            stroke="transparent"
            fillOpacity={1}
            fill="transparent"
            className="z-0"
            activeDot={(props: any) =>
              renderActiveDot(props, savingsAgeRange[1], savingsAgeRange[0])
            }
          />
          <Legend
            content={renderCustomLegend(
              ctaColor,
              ctaText,
              ctaTextColor,
              registrationButton,
              ctaDestination,
              webregURL
            )}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};
